import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { faCircleChevronRight } from '@fortawesome/pro-solid-svg-icons'

function properCase(str) {
  return str
    .split('-')
    .map(s => s.slice(0, 1).toUpperCase() + s.slice(1))
    .join(' ')
}

const Sitemap = ({ data }) => {
  const news = data.news.edges
  const heroImage = data.heroImage
  const legal = data.legal.edges

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-40"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="UNICOM Global sitemap"
          subtitle=""
          type="sitemap"
        />
      </div>
      <section className="white">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="4" className="mb-5">
              <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small mt-lg-5">
                Home 
                <Link to="/" className="ml-2 linkedin"> 
                  <FontAwesomeIcon icon={faLink} /> 
                </Link>
              </h2>
              <p className="font-w-400 text-medium">
                <Link to="/unicom-global-and-ibm-zsystems/" className="effect-noline">UNICOM Global and IBM zSystems</Link>
              </p>

              <h2 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-small  mt-0 mt-md-5">
                  Company <Link to="/about/" className="ml-2 linkedin"> <FontAwesomeIcon icon={faLink} /> </Link> </h2>
              <p className="font-w-400 text-medium">
                <Link to="/about/" className="effect-noline">About Us</Link>
              </p>
              <p className="font-w-400 text-medium">
                <Link to="/contact/" className="effect-noline">Contact Us</Link>
              </p>
              <p className="font-w-400 text-medium">
                <Link to="/about/corry-hong-founder-and-ceo-unicom-global/" className="effect-noline">Corry Hong: Founder and CEO</Link>
              </p>

              <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                Product catalog
                <Link to="/product-catalog/" className="ml-2 linkedin">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </h2>
            </MDBCol>

            <MDBCol md="4" className="mb-5">
            <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                Divisions
                <Link to="/divisions/" className="ml-2 linkedin">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </h2>

              <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-0 mt-md-5">
                Locations
                <Link to="/locations/" className="ml-2 linkedin">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </h2>

              <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                Careers
                <Link to="/careers/" className="ml-2 linkedin">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </h2>

              <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-lg-5">
                Search
                <Link to="/search/" className="ml-2 linkedin">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </h2>
            </MDBCol>

            <MDBCol md="4" className="mb-5">
              <h2 className="font-alt font-w-700 letter-spacing-1 title-small mt-5">
                Privacy &amp; legal
              </h2>
              {legal.map(({ node }, index) => (
                <p className="font-w-400 text-medium" key={index}>
                  <Link to={node.fields.slug} className="effect-noline">
                    {properCase(node.frontmatter.title)}
                  </Link>
                </p>
              ))}
            </MDBCol>

            <MDBCol md="12">
              <h2 className="font-alt font-w-700 letter-spacing-1 title-small">
                News
                <Link to="/news/" className="ml-2 linkedin">
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </h2>
              {news.map(({ node }, index) => (
                <p className="font-w-400 text-medium" key={index}>
                  <Link to={`/news/${node.slug}/`} className="effect-noline">
                    {properCase(node.title)}
                  </Link>
                </p>
              ))}
              <ul className="fa-ul text-medium ml-4">
                <li><span className="fa-li"><FontAwesomeIcon icon={faCircleChevronRight} className="ico-color-grey va-m" size="xs" /></span>
                    <Link to="/news/" className="effect-noline">More news</Link>
                </li>
              </ul>   
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default Sitemap 

export function Head({ data, location }) {
  const heroImage = data.heroImage
  return (
    <SEO
      title="UNICOM Global - Sitemap"
      description="Use this sitemap to find the content you're looking for and then navigate your way around the UNICOM Global website."
      url={location.pathname} 
      image={heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
      canonical={location.pathname}                        
    />
  )
}

export const query = graphql`
  query SitemapQuery {  
    news: allContentfulNews(
      filter: {
        publishTo: {
          elemMatch: { url: { eq: "https://www.unicomglobal.com" } }
        }
      }
      sort: { fields: date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          slug
          title
          date
        }
      }
    }
    legal: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "legal" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    about: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "about" } } }
      sort: { fields: frontmatter___title, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    heroImage: file(name: { eq: "sitemap-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`